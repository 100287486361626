/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
    display: none;
}

/* #root{
    height: 100vh !important;
} */

/* Tailwind CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

.ql-container{
    height: 200px !important;
}